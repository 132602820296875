.bold_text {
  color: #0060a3;
  font-size: 2em;
  font-weight: bold;
}
.bold_text2 {
  font-size: 1.5em;
  color: #0060a3;
}
.simple_btn {
  background: linear-gradient(#f6c696, #e9893d) !important;
}
.price_tag {
  font-weight: bold;
  color: #0060a3;
}

.bodyTxtBold_iv_rpt {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #0060a3;
}
.bodyTxtBold_iv_rpt_option {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #566573;
  text-align: center;
}
.IV_reports p {
  font-size: 0.9em;
  color: #566573;
}
.pts_list_row {
  border-bottom: 1px solid #d6eaf8;
  padding: 10px;
}
.report_details_list {
  list-style: disc;
  color: #0060a3;
  font-weight: 600;
  font-size: 0.8em;
}

.report_content {
  font-size: 1.1em;
  color: #566573;
}

.breadcrumbs_fix_top_all_rd {
  position: fixed;
  width: 101%;
  margin-top: -1.4em;
  margin-bottom: 0em;
  z-index: 1;
}
