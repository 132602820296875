/* body {
  margin-top: 20px;
  color: #1a202c;
  text-align: left;
  background-color: #e2e8f0;
}*/
.userProfile .main-body {
  padding: 15px;
}

.userProfile .card {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.userProfile .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.userProfile .card-header {
  text-align: left;
  padding: 5px !important;
  height: 2em;
  color: #4484c1;
  font-weight: 700;
}
.userProfile .card-body {
  flex: 1 1 auto;
  min-height: 1px;
}

.userProfile .gutters-sm {
  margin-right: -8px;
  margin-left: -8px;
}
.userProfile hr {
  margin: 0.3rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}
.userProfile .text-secondary {
  color: #6c757d !important;
  text-align: left;
  font-weight: 500;
}
.gutters-sm > .col,
.gutters-sm > [className*="col-"] {
  padding-right: 8px;
  padding-left: 8px;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.bg-gray-300 {
  background-color: #e2e8f0;
}
.h-100 {
  height: 100% !important;
}
.shadow-none {
  box-shadow: none !important;
}
.order_card {
  margin: 0px;
  padding: 0px !important;
  overflow-y: scroll;
  height: 20em;
}
.plan {
  display: inline-block;
  margin: 10px 1%;
  font-family: "Lato", Arial, sans-serif;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.user_column {
  text-align: left;
  color: #2980b9;
  font-weight: 600;
}
.pts_mobile_view {
  margin-top: 2.5em;
}
@media (max-width: 768px) {
  .userProfile .gutters-sm {
    margin-right: -0.8em;
    margin-left: auto;
  }
  .userRow{
text-align: center !important;
  }
}
.plan-inner1 {
  background: #fff;
  margin: 0 auto;
  min-width: 280px;
  max-width: 100%;
  position: relative;
  /* height: 30.5em; */
}
.userRow{
  text-align: left;
    }