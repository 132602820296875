.react-tabs__tab-panel {
  background: #fff !important;
  border-left: 0.5px solid #1b7189;
  border-right: 0.5px solid #1b7189;
  border-bottom: 0.5px solid #1b7189;
  min-height: 100vh;
  font-size: small;
}
.react-tabs__tab {
  min-width: 10%;
  text-align: center;
  border-radius: 5px 5px 0 0;
  /* background: #012970; */
  color: #000;
  border: 0.5px solid #1b7189 !important;
  border-bottom-color: rgb(27, 113, 137);
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  border-bottom: none !important;
  margin-right: 2px;
  font-size: small;
}

.react-tabs__tab--selected {
  min-width: 10%;
  text-align: center;
  border-radius: 5px 5px 0 0;
  background: #fff;
  color: #012970;
  border: 0.5px solid #1b7189 !important;
  border-bottom-color: rgb(27, 113, 137);
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  border-bottom: none !important;
  margin-right: 2px;
  font-size: small;
}

.defExTab .nav-item.show .nav-link,
.defExTab .nav-link.active {
  /* color: #495057; */
  background-color: #fff;
  border-color: transparent !important;
  border-bottom: 2px solid #013289 !important;
}

.defExTab .nav-link {
  margin-top: 10px;
  background-color: mediumaquamarine;
  margin-left: 35px;
  color: #fff;
}

.defExTab .nav-link.active {
  /* background-color: mediumaquamarine; */
  margin-left: 35px;
  color: mediumaquamarine !important;
  border-bottom: 2px solid mediumaquamarine !important;
}
.defExt_Table .MuiPaper-root {
  z-index: 0;
}
.defExTab {
  border-bottom: 1px solid #dee2e6;
  margin-top: 10px;
}
/* .cusTabs .MuiTableRow-root:nth-child(odd) {
  background-color: rgb(255, 255, 255) !important;
  border: none !important;
} */

.def_ext .MuiTab-root {
  font-size: 0.925rem;
  text-align: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: bold !important;
  line-height: 1.75;
  white-space: normal;
  letter-spacing: 0.02857em;
  text-transform: capitalize;
  margin: 0px 15px;
}
.def_ext .MuiTab-textColorPrimary.Mui-selected {
  color: rgb(242, 109, 33);
  margin: 0px 15px;
}
.def_ext .MuiTabs-fixed {
  overflow: hidden;
  /* background: #fff; */
  width: 100%;
  margin-left: -24px;

  /* margin-top: -0.8em; */
}
.pts_mobile_view {
  margin-top: 1em;
}
