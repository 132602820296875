.feedback_section p {
  text-align: left;
}
.breadcrumbs h4 {
  text-align: left;
}

@media (max-width: 768px) {
  .breadcrumbs h4 {
    text-align: left;
    font-size: 1.1em;
    margin-top: 0.5em;
  }
  .companySnapBreadcrumbs h4 {
    text-align: left;
    font-size: 1.1em;
    margin-top: 0.5em;
  }
  .feedback_column {
    margin: 0 0 1em 0 !important;
  }
}
.send_btn {
  font-size: 1.3em;
  border-radius: 0px;
  background: #012970;
}
.feedbackMessage {
  color: rgb(242, 109, 33) !important;
  font-weight: bold;
  margin: 0em 0em 3em 3em;
}
.feedback_column {
  margin: 0 0 0 0;
}
.companySnapBreadcrumbs h4 {
  text-align: left;
  font-size: 1.1em;
  margin-top: 0.5em;
}
