.accounting_ratio tbody,
.accounting_ratio td,
.accounting_ratio tfoot,
.accounting_ratio th,
.accounting_ratio thead,
.accounting_ratio tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border: 1px solid gray !important;
  padding: 10px 0px 0px 5px;
  font-size: 0.9em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.accounting_ratio td,
.accounting_ratio tfoot {
  text-align: right;
  padding: 0px 5px 0px 0px;
}

.accounting_ratio tr:nth-child(even) {
  background: rgb(202, 225, 255);
}
.accounting_ratio tr:nth-child(odd) {
  background: #fff;
}
