.financial_analysis th,
td {
  font-size: 0.8em;
}
.unit_str {
  font-size: 0.8em;
  font-weight: bold;
  color: #34495e;
  padding: 3px;
  font-style: italic;
}
