.qualityRatingHeading {
  font-weight: bold;
  color: #1a5276;
  text-align: center;
  text-decoration: underline;
}

.corporate_growth tbody,
.corporate_growth td,
.corporate_growth tfoot,
.corporate_growth th,
.corporate_growth thead,
.corporate_growth tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border: 1px solid gray !important;
  padding: 11px 0px 0px 5px;
  font-size: 0.9em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.corporate_growth td,
.corporate_growth tfoot {
  text-align: right;
  padding: 0px 5px 0px 0px;
}
.corporate_growth th,
.corporate_growth thead {
  width: 85%;
}
.corporate_growth tr:nth-child(even) {
  background: rgb(202, 225, 255);
}
.corporate_growth tr:nth-child(odd) {
  background: #fff;
}
