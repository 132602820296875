.breadcrumbs_fix_top_all {
  position: fixed;
  width: 101%;
  margin-top: -6.6em;
  margin-bottom: 0em;
  z-index: 11;
  right: 0;
  left: 0;
}
.companySnap_breadcrumbs_fix_top_all {
  position: fixed;
  width: 101%;
  margin-top: -7.6em;
  margin-bottom: 0em;
  z-index: 11;
  right: 0;
  left: 0;
}
