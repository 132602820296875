.liquid_analysis tbody,
.liquid_analysis td,
.liquid_analysis tfoot,
.liquid_analysis th,
.liquid_analysis thead,
.liquid_analysis tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border: 1px solid gray !important;
  padding: 10px 0px 0px 5px;
  font-size: 0.9em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.liquid_analysis td,
.liquid_analysis tfoot {
  text-align: right;
  padding: 0px 5px 0px 0px;
}

.liquid_analysis tr:nth-child(even) {
  background: rgb(202, 225, 255);
}
.liquid_analysis tr:nth-child(odd) {
  background: #fff;
}
