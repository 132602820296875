.sidebar {
  margin: 0;
  padding: 0;
  width: 24.5%;
  background-color: transparent;
  position: fixed;
  /* height: 30em; */
  overflow: auto;
  /* margin-top: 10em; */
  scrollbar-width: thin;
  /* margin-top: 7em; */
  overflow-y: auto;
  height: 33em;
}
.sidebar_mobile {
  margin: 0;
  padding: 0;
  width: 336px;
  background-color: black;
  position: fixed;
  /* height: 30em; */
  overflow: auto;
  /* margin-top: 10em; */
  scrollbar-width: thin;
  margin-top: 2.5em;
  overflow-y: auto;
  height: 30em;
}
/* .sidebar::-webkit-scrollbar {
  width: 7px;
} */
:-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #99a3a4;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.sidebar a {
  display: block;
  color: #b2babb;
  /* font-weight: bold; */
  padding: 7px;
  text-decoration: none;
}
.sidebar_mobile a {
  display: block;
  color: #fff;
  /* font-weight: bold; */
  padding: 7px;
  text-decoration: none;
}
.sidebar_mobile a.active {
  background-color: transparent;
  border-left: 5px solid #4caf50 !important;
  color: white;
  font-weight: bold;
}
/* .active {
  background-color: transparent;
  border-left: 5px solid #4caf50 !important;
  color: white;
  font-weight: bold;
} */
.sidebar a.active {
  background-color: transparent;
  border-left: 5px solid #4caf50 !important;
  color: white;
  font-weight: bold;
}

.sidebar a:hover:not(.active) {
  background-color: #b3d9aa;
  color: white;
}

div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 1000px;
}

/* @media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
    background: #000;
  }
  .report_Li_list {
    border-bottom: 1px solid transparent;
    padding: 0.3em;
  }
  .sidebar a {
    float: left;
  }
  div.content {
    margin-left: 0;
  }

  .company_Name {
    display: none;
  }
} */

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}

.company_info {
  padding: 10px;
}
.company_info h6 {
  font-weight: bold;
}
/* .company_snap {
  color: white;
} */

/* .company_snap h6 {
  color: white;
  font-weight: bold;
} */

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
  float: left;
  font-weight: "100" !important;
}
.currency_Info {
  font-size: 0.8em;
}
.cmpny_info_section {
  padding: 25px 0px;
  background: #f6f7fa;
  /* margin: 0px; */
}
.company_title {
  font-weight: bold;
  font-size: 1.5em;
  color: #dc7633;
}
.section_header_count {
  font-size: 1.4em;
  border-right: 1px solid;
  font-weight: bold;
  padding: 3px;
  color: #34495e;
}
.header_count {
  font-size: 2em;
  border-right: 1px solid;
  font-weight: bold;
  padding: 3px;
  color: #34495e;
}
