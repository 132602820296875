.emp_efficiency tbody,
.emp_efficiency td,
.emp_efficiency tfoot,
.emp_efficiency th,
.emp_efficiency thead,
.emp_efficiency tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border: 1px solid gray !important;
  padding: 10px 0px 0px 5px;
  font-size: 0.9em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.emp_efficiency td,
.emp_efficiency tfoot {
  text-align: right;
  padding: 0px 5px 0px 0px;
}

.emp_efficiency tr:nth-child(even) {
  background: rgb(202, 225, 255);
}
.emp_efficiency tr:nth-child(odd) {
  background: #fff;
}
.emp_data_error_msg {
  font-weight: bold;
  text-decoration: underline;
  color: rgb(242, 109, 33);
  padding: 4em 15em;
}
