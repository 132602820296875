@keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 1920px 0;
  }
}
@-webkit-keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 1920px 0;
  }
}
.service-block-one a {
  text-decoration: none;
}

@keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 1920px 0;
  }
}
@-webkit-keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 1920px 0;
  }
}
.service-block-one .inner-box {
  position: relative !important;
  display: block !important;
  overflow: hidden !important;
  text-align: center !important;
  /* background: #eaf2f8 !important; */
  /* background: linear-gradient(
    135deg,
    #f6f8f9 0%,
    #e5ebee 50%,
    #d7dee3 51%,
    #f5f7f9 100%
  ); */
  border: 0.5px solid #b9b9b9;
  cursor: pointer;
  padding: 1px 7px 8px !important;
  height: 12em;
  background-size: 100% 200%;

  /* transition: background-position 0.2s ease-in-out, color 0.2s ease-in-out; */
}
.service-block-one .inner-box:before {
  position: absolute !important;
  content: "";
  width: 100% !important;
  height: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  transition: all 500ms ease !important;
}
.service-block-one .inner-box:hover:before {
  height: 100%;
  top: 0;
}
.service-block-one .inner-box .icon-box1 {
  position: relative !important;
  display: inline-block !important;
  font-size: 100px !important;
  line-height: 40px !important;
  margin-bottom: 8px !important;
  z-index: 1 !important;
  transition: all 500ms ease !important;
}
/* .service-block-one .inner-box .icon-box1:before {
  position: absolute !important;
  content: "" !important;
  background: #303030 !important;
  width: 72px !important;
  height: 72px !important;
  border-radius: 50% !important;
  left: -9px !important;
  top: -13px !important;
  z-index: -1 !important;
  transition: all 500ms ease !important;
}*/
.service-block-one {
  box-shadow: 0 7px 0 #bfbaad, 0 0.5em 0 #a8a38c, 0 12px 12px #a8a38c;
  /* box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16), 0 4px 6px rgba(0, 0, 0, 0.45); */
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); */
}
.service-block-one .inner-box:hover .icon-box1 {
  transform: rotateY(360deg);
  color: #fff !important;
}
.service-block-one .inner-box:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1);
  transition: background-position 0.2s ease-in-out, color 0.2s ease-in-out;
}
.service-block-one .inner-box h3 {
  position: relative !important;
  display: block !important;
  font-size: 22px !important;
  line-height: 30px !important;
  color: #fff !important;
  font-weight: 800 !important;
  margin-bottom: 9px !important;
}
.service-block-one .inner-box h3 a {
  display: inline-block !important;
  /* color: rgb(211, 84, 0) !important; */
  font-size: 0.8em;
}
.service-block-one .inner-box p {
  position: relative !important;
  /* color: #012970 !important; */
  margin-bottom: 33px !important;
  font-size: 0.7em;
}
.service-block-one .inner-box .theme-btn {
  padding: 6px 25px !important;
}
.service-block-one .inner-box:hover .theme-btn {
  background: #de3f40 !important;
}
.service-section .sec-title {
  margin-bottom: 70px;
}
.service-section .sec-title span {
  margin-bottom: 21px;
}
.service-block-one .inner-box .icon-box1 {
  color: #da292a !important;
}
.service-block-one .inner-box:before {
  background: #da292a !important;
}
.theme-btn {
  position: relative;
  display: inline-block;
  font-size: 15px;
  background: rgb(242, 109, 33);
  line-height: 26px;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
  color: #fff !important;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1;
  transition: all 500ms ease;
}
.theme-btn:before {
  position: absolute;
  content: "";
  background: #1c1d1f;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.theme-btn:hover:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.theme-btn i {
  position: relative;
  top: 2px;
  font-size: 20px;
  margin-left: 40px;
}
@media (max-width: 1024px) {
  .service-block-one .inner-box {
    padding: 30px;
  }
  .service-block-one {
    margin-bottom: 15px;
  }
  .theme-btn {
    padding: 10px 20px;
  }
}
.btn-box {
  margin-top: 1em;
}
.home_news_feeds .alice-carousel__wrapper {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 6em;
}
.home_news_feeds {
  padding-bottom: 1em;
}
.testim .cont div h2 {
  color: currentColor !important;
  font-size: 1em;
  margin: 15px 0;
  font-weight: bold !important;
}
.market_commentary_header {
  background: #808b96;
  color: #fff;
  text-align: left;
  padding: 6px 0 0 9px;
  font-weight: 600;
}
.footer .footer-top h4 {
  color: #fff;
}
.tag_line_home {
  margin-top: 0em;
  margin-left: -3.4em;
}
.app_src_btn {
  display: block;
  float: right;
}
