.perShare_data tbody,
.perShare_data td,
.perShare_data tfoot,
.perShare_data th,
.perShare_data thead,
.perShare_data tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border: 1px solid gray !important;
  padding: 10px 0px 0px 5px;
  font-size: 0.9em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.perShare_data td,
.perShare_data tfoot {
  text-align: right;
  padding: 0px 5px 0px 0px;
}

.perShare_data tr:nth-child(even) {
  background: rgb(202, 225, 255);
}
.perShare_data tr:nth-child(odd) {
  background: #fff;
}
