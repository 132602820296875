.financial_analysis1 tbody,
.financial_analysis1 td,
.financial_analysis1 tfoot,
.financial_analysis1 th,
.financial_analysis1 thead,
.financial_analysis1 tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border: 1px solid gray !important;
  padding: 10px 0px 0px 5px;
  font-size: 0.9em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.financial_analysis1 td,
.financial_analysis1 tfoot {
  text-align: right;
  padding: 0px 5px 0px 0px;
}

.financial_analysis1 tr:nth-child(even) {
  background: rgb(202, 225, 255);
}
.financial_analysis1 tr:nth-child(odd) {
  background: #fff;
}
.graph_link {
  padding: 0px;
  line-height: 4em;
}
.modalContain .modal-header {
  background: rgb(40, 104, 148);
  color: #fff;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0px !important;
  border-bottom: 1px solid transparent !important;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  height: 3.5em;
}
.modalContain .modal-header > h5 {
  color: #fff !important;
}
.modalContain .modal-header > h5 > label {
  color: #fff !important;
}
.modalContain .modal-header > h5 > span {
  color: #fff !important;
  font-size: 0.5em;
}

.modal-title > .close {
  background: #fff;
  border-radius: 50%;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1;
  font-weight: bold;
  padding: 0.5em;
  width: 100%;
}
