.company_snap_tabs .MuiTab-root {
  font-size: 0.925rem;
  text-align: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: bold !important;
  line-height: 1.75;
  white-space: normal;
  letter-spacing: 0.02857em;
  text-transform: capitalize;
}
.company_snap_tabs .MuiTab-textColorPrimary.Mui-selected {
  color: rgb(242, 109, 33);
}
.company_snap_tabs .MuiTabs-fixed {
  overflow: hidden;
  position: fixed;
  background: #fff;
  width: 100%;
  margin-left: -24px;
  z-index: 1;
  margin-top: -1em;
}
@media (max-width: 768px) {
  .pts_company_snap_mobile_view {
    margin-top: 2em;
  }
  .section_card .nav-tabs {
    border-bottom: 1px solid #dee2e6;
    float: left;
    font-weight: bold;
    font-weight: "100" !important;
    padding: 2em;
  }
  .company_snap_tabs .MuiTabs-fixed {
    overflow: auto !important;
    position: fixed;
    background: #fff;
    width: 100%;
    margin-left: -18px;
    z-index: 1;
    margin-top: 1.2em;
  }
  .company_snap_info {
    margin-top: 5em;
  }
  .breadcrumbs_fix_top {
    position: fixed;
    width: 101%;
    margin-top: -2.6em !important;
    margin-bottom: -3em;
    z-index: 1;
  }
  .default_select {
    background: transparent !important;
  }
  .drawer_float {
    position: fixed;
    width: 30px;
    height: 30px;
    bottom: 371px;
    right: 309px;
    background-color: rgb(242, 109, 33);
    color: #fff;
    border-radius: 50px;
    text-align: center;
    display: block !important;
    z-index: 1 !important;
  }
}
.tab_to_top {
  margin-top: -4.5em;
}

.breadcrumbs_fix_top {
  position: fixed;
  width: 101%;
  margin-top: -1.6em;
  margin-bottom: -3em;
  z-index: 3;
}
.tab_to_scroll_top {
  scroll-margin-top: 0 !important;
}

.sidebar {
  margin: 0;
  padding: 0;
  width: 200px;
  background-color: #f1f1f1;
  color: #fff !important;
  /* position: fixed; */
  /* height: 25em; */
  overflow: auto;
  /* margin-top: 2em; */
  scrollbar-width: thin;

  /* scrollbar-width: thin; */
}
.sidebar::-webkit-scrollbar {
  width: 5px;
}
.is-active {
  font-weight: 700;
  background-color: rgb(242, 109, 33);
  color: #fff;
  display: block;
}
.default_select {
  background: rgb(242, 109, 33);
}
.default_unselect {
  background: transparent;
}
/* .is-active .submenu {
  font-weight: 700;
  background-color: #013289;
  color: #fff;
  display: block;
} */
.toggle_btn {
  display: none;
}
@media screen and (max-width: 768px) {
  .pts_company_snap_mobile_view {
    margin-top: 2em !important;
  }
  .section_card .nav-tabs {
    border-bottom: 1px solid #dee2e6;
    float: left;
    font-weight: bold;
    font-weight: "100" !important;
    padding: 2em;
  }
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
    background: #000;
    border-bottom: none;
    display: none;
  }
  .sidebar a {
    float: left;
  }
  div.content {
    margin-left: 0;
  }
  .bgImg {
    margin-top: 9em;
  }
  .report_Li_list {
    border-bottom: 1px solid transparent;
    padding: 0.3em;
  }
  .toggle_btn {
    display: block;
  }
  .sidebar_mobile {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
    background: #000;
    margin-top: 2.3em;
    border-bottom: none;
  }
  .company_Name {
    display: none;
  }
}
/* @media screen and (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
    background: #000;
    border-bottom: none;
    display: none;
  }
  .sidebar a {
    float: left;
  }
  div.content {
    margin-left: 0;
  }
  .bgImg {
    margin-top: 9em;
  }
  .report_Li_list {
    border-bottom: 1px solid transparent;
    padding: 0.3em;
  }
  .toggle_btn {
    display: block;
  }
  .sidebar_mobile {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
    background: #000;
    margin-top: 2.3em;
    border-bottom: none;
  }
  .company_Name {
    display: none;
  }
} */

@media (max-width: 1023.9px) {
  .sidebar {
    width: 20%;
  }
  .company_Name {
    font-size: 80%;
  }
  .pts_company_snap_mobile_view {
    margin-top: 2em;
  }
  .section_card .nav-tabs {
    border-bottom: 1px solid #dee2e6;
    float: left;
    font-weight: bold;
    font-weight: "100" !important;
    padding: 2em;
  }
}

@media (max-width: 1000px) {
  .sidebar {
    width: 30%;
  }
  .company_Name {
    font-size: 80%;
  }
  .pts_company_snap_mobile_view {
    margin-top: 2em;
  }
  .section_card .nav-tabs {
    border-bottom: 1px solid #dee2e6;
    float: left;
    font-weight: bold;
    font-weight: "100" !important;
    padding: 2em;
  }
}

@media (min-width: 1024px) {
  .sidebar {
    width: 50%;
    /* display: contents; */
  }
  .company_Name {
    font-size: 80%;
  }
  .pts_company_snap_mobile_view {
    margin-top: 2em;
  }
  .section_card .nav-tabs {
    border-bottom: 1px solid #dee2e6;
    float: left;
    font-weight: bold;
    font-weight: "100" !important;
  }
  
}
/*
@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
} */
.menu_List {
  list-style: none;
  padding: 0px;
}
.sidebar ul li a {
  color: #fff;
  padding: 7px;
}
.section_card .nav-tabs {
  border-bottom: 1px solid #dee2e6;
  float: left;
  font-weight: bold;
  font-weight: "100" !important;
}
.sidebar li:hover .submenu {
  display: block;
  max-height: 200px;
}
.submenu {
  overflow: hidden;
  max-height: 0;
  -webkit-transition: all 0.5s ease-out;
}
.mystyle {
  position: fixed;
  top: 0;
}
.section_header {
  color: #34495e;
  font-weight: bold;
  font-size: 1.3rem;
}
.section_icon {
  color: #dc7633;
  font-size: 1.2em;
}
/* .section_card {
  box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px,
    rgb(0 0 0 / 19%) 0px 6px 20px 0px;
  background: #abb2b9;
} */
.dropdownClose {
  display: none;
}
.dropdownExpand {
  display: block;
}

#fundamentalReport {
  width: 100%;
  /* background-color: #555; */
  /* list-style: none; */
}
#financialStatementReport {
  width: 100%;
  /* background-color: #555; */
  /* list-style: none; */
}
#financialRatiosReport {
  width: 100%;
  /* background-color: #555; */

  /* list-style: none; */
}
.report_Li_list {
  border-bottom: 1px solid #abb2b9;
  padding: 0.3em;
}
/* .cmp_data h6 {
  color: #1f618d;
  font-style: oblique;
}
.cmp_data label {
  color: #283747;
  font-style: italic;
  text-decoration: underline;
} */
.cmp_data h6 {
  color: #dee2e6;
  font-style: oblique;
}
.cmp_data label {
  color: #fff;
  font-style: italic;
  /* text-decoration: underline; */
}
/* .bgImg {
  background-image: url("./bg1.png");
  background-clip: content-box;
  background-size: cover;
  background-repeat: no-repeat;
} */
.cmp_website {
  color: #4154f1;
  text-decoration: underline;
}
#wa_analysis,
#company_Financial,
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #34495e;
}

#wa_analysis,
#company_Financial,
.nav-tabs .nav-link.active {
  color: #c0392b;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.section_main_header {
  color: #34495e;
  font-weight: bold;
  font-size: 1.6rem;
}
.section_main_header_cba {
  color: #34495e;
  font-weight: bold;
  font-size: 1.6rem;
}
.company_Name {
  position: fixed;
  width: 26%;
  height: 8.8em;
  border-bottom: 1px solid rgb(171, 178, 185);
  padding: 10px;
}
.company_Name_onScroll {
  position: fixed;
  width: 350px;
  height: 7em;
  border-bottom: 1px solid rgb(171, 178, 185);
  margin: -5em 0em 0em 0em;
  padding: 10px;
}
.earn_dividend_table tbody,
.earn_dividend_table td,
.earn_dividend_table tfoot,
.earn_dividend_table th,
.earn_dividend_table thead,
.earn_dividend_table tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border: 1px solid #4f5050;
  padding: 13px;
  color: rgb(255, 255, 255);
}
.earn_dividend {
  padding: 16px 0px 0px 0px;
}

.unit_str1 {
  color: rgb(220, 118, 51);
}
.read_more {
  color: #fff;
  font-weight: bold;
  float: right;
  padding: inherit;
  cursor: pointer;
  margin-top: -1.2em;
}
.company_Info {
  padding: 19px;
  color: #ccd1d1;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: medium;
  padding-top: 0px;
}
.cmp_unit_str {
  font-size: 0.8em;
  font-weight: bold;
  color: #dee2e6;
  padding: 3px;
  font-style: italic;
  margin-left: 1.5em;
}
.pts_mobile_view {
  margin-top: 1.2em;
}
.pts_company_snap_mobile_view {
  margin-top: -0.7em;
}
.MuiDrawer-paperAnchorLeft {
  left: 0;
  right: auto;
  background: linear-gradient(
    to left,
    rgb(20, 30, 48),
    rgb(36, 59, 85)
  ) !important;
}
.MuiDrawer-paperAnchorLeft a {
  color: #fff;
}

.drawer_float {
  display: none;
}
.my-float {
  margin-top: 2px;
}
.MuiDrawer-paper {
  top: 185px !important;
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
}
