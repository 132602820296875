.advance_header {
  color: #012970;
  font-weight: bold;
}
.infoData {
  font-size: 0.8em !important;
}
.filter_card {
  padding: 10px;
  min-height: 7em;
  box-shadow: inset 0 0 0 1px #bbcfeb;
  background: rgb(242, 243, 244) !important;
  margin-bottom: 0.5em;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-right: 65px;
  background: #fff;
  min-height: 1em;
}

.download_button {
  background: #99b6df;
  background: -webkit-gradient(
    linear,
    0 0,
    0 bottom,
    from(#99b6df),
    to(#638ec8)
  );
  background: -moz-linear-gradient(#99b6df, #638ec8);
  background: linear-gradient(#99b6df, #638ec8);
  border: solid 1px #6d94ce;
  border-bottom: solid 3px #3867ac;
  box-shadow: inset 0 0 0 1px #bbcfeb;
  color: #fff;
  text-shadow: 0 1px 0 #3c61ab;
}
.react-checkbox-tree {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  font-size: 22px;
}
.react-checkbox-tree button{
  background: #fff !important;
}
.cancel_button {
  background: #f97779;
  background: -webkit-gradient(
    linear,
    0 0,
    0 bottom,
    from(#f97779),
    to(#ce2424)
  );
  background: -moz-linear-gradient(#f97779, #ce2424);
  background: linear-gradient(#f97779, #ce2424);
  border: solid 1px #be2424;
  border-bottom: solid 3px #bd2524;
  box-shadow: inset 0 0 0 1px #e67e7b;
  color: #fff;
  text-shadow: 0 1px 0 #bd2524;
}
.highlight_text {
  color: rgb(211, 84, 0);
}
.rct-title {
  padding: 0 5px;
  font-size: 0.7em !important;
}
.advance_searchCmp {
  max-height: 50em;
  overflow-x: auto;
}
