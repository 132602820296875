.sales_analysis {
  text-align: center;
  font-weight: bold;
}
.view_Data {
  font-weight: bold;
  float: right;
  cursor: pointer;
  /* color: #c0392b; */
  color: #c0392b;
  text-decoration: underline;
  padding: 0.5em;
}
