.checkout {
  background: #044563;
  border-radius: 10px;
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.mainContentWrapper .panel-group {
  margin-bottom: 20px;
}
.mainContentWrapper .panel-group .panel {
  margin-bottom: 0;
  border-radius: 4px;
}
.mainContentWrapper .panel-default {
  border-color: #ddd;
}
.mainContentWrapper .panel {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}
.mainContentWrapper .panel-group .panel-heading {
  border-bottom: 0;
}
.mainContentWrapper .panel-default .panel-heading {
  color: #fff;
  background-color: #044563;
  border-color: #ddd;
}
.mainContentWrapper .panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.mainContentWrapper .collapse.in {
  display: block;
  visibility: visible;
}
.mainContentWrapper
  .panel-default
  > .panel-heading
  + .panel-collapse
  > .panel-body {
  border-top-color: #ddd;
}
.mainContentWrapper .panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 1px solid #ddd;
}

.mainContentWrapper .panel-body {
  padding: 15px;
}

.mainContentWrapper .panel-default .panel-heading {
  color: #fff;
  background-color: #044563;
  border-color: #ddd;
}

.mainContentWrapper .panel-default .panel-heading {
  color: #fff;
  background-color: #044563;
  border-color: #ddd;
}

.mainContentWrapper .panel-default .panel-heading {
  color: #fff;
  background-color: #044563;
  border-color: #ddd;
}
