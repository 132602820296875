.fixCharge_coverage tbody,
.fixCharge_coverage td,
.fixCharge_coverage tfoot,
.fixCharge_coverage th,
.fixCharge_coverage thead,
.fixCharge_coverage tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border: 1px solid gray !important;
  padding: 10px 0px 0px 5px;
  font-size: 0.9em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.fixCharge_coverage td,
.fixCharge_coverage tfoot {
  text-align: right;
  padding: 0px 5px 0px 0px;
}

.fixCharge_coverage tr:nth-child(even) {
  background: rgb(202, 225, 255);
}
.fixCharge_coverage tr:nth-child(odd) {
  background: #fff;
}
