.jZkBQk > .wrapper {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  border: 1px solid #dfe1e5;
  border-radius: 0px !important;
  background-color: white;
  color: #212121;
  font-size: 16px;
  font-family: Arial;
  z-index: 99999;
}
.eOPJCx {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin: 0 0 0 16px;
  fill: grey;
  display: none;
}
.search_with_result .wrapper {
  height: 46px !important;
}

.header_search .frjscc {
  min-height: 29px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.bWZouD {
  z-index: 1111 !important;
}
.sc-bwzfXH .bWZouD {
  z-index: 1111 !important;
}
.generic_search_box_home {
  width: 60%;
}
@media (max-width: 768px) {
  .generic_search_box_home {
    width: 76%;
  }
  .company_list_box_home {
    width: 67.2% !important;
  }
  .company_list_box_header {
    width: 59.8% !important;
  }
  .gridSearchClass{
    display: flex;
      width: 43% !important;
  }
}
.company_list_box_home {
  text-align: left;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  border-top: 1px solid gray;
  /* background: lavenderblush; */
  background: #000;
  position: fixed;
  z-index: 11;
  width: 28.2%;
}
.company_list_box_header {
  text-align: left;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  border-top: 1px solid gray;
  /* background: lavenderblush; */
  background: #000;
  position: fixed;
  z-index: 11;
  width: 22.8%;
}
.gridSearchClass{
  display: flex;
    width: 30%;
}