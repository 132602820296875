.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0px !important;
  padding-right: 0px !important;
}
.exchange_h4 {
  line-height: 1em;
  float: right;
  color: black;
  margin-top: 20px;

  font-family: Arial, Verdana, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: bold;
}
.exchange_card_header {
  background: none !important;
  border: none !important;
}
.MuiTableHead-root {
  display: table-header-group;
  background: #e6ebf1;
}
.bodyTxtBold {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #5a5a5a;
}
.bodyTxtBold {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #5a5a5a;
}
.bodyTxtRed {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 11px;
  line-height: 14px;
  color: red;
  font-weight: bold;
}
.currency_Table {
  text-align: center;
  /* margin:20px; */
}

.price_date {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #5a5a5a;
}
.selected_currency {
  font-family: Arial, Verdana, Helvetica, sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #003b6d;
}
.selected_currency_side {
  font-family: Arial, Verdana, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: bold;
  /* color: #5a5a5a; */
  color: black;
}
.bodyTxt {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: #5a5a5a;
}
.currency_row {
  outline: 0;
  margin: 0 auto;
  padding-bottom: 0.3em;
}
.col_background {
  /* background: linear-gradient(to bottom, #ccccff 0%, #ccffcc 100%); */
  /* background: linear-gradient(to bottom, #ccccff 100%, #ccffcc 100%); */
  /* background: #fff !important; */
  border: none !important;
  /* border-radius: 2px !important; */
  /* background: rgba(255,255,255, 0.2) !important; */
  background: none !important;
}
.currency_link {
  text-decoration: underline;
  color: black;
  cursor: pointer;
}
.pageHeight {
  min-height: 35em !important;
}
/* .MuiTableCell-alignCenter {
  text-align: center;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border: 1px solid;
} */
/* .MuiTableCell-alignLeft {
  text-align: left;
  border: 1px solid;
} */
/* .MuiTableCell-root {
  display: table-cell;
  padding: 16px;
  font-size: 0.875rem;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 1px solid black !important;
  letter-spacing: 0.01071em;
  vertical-align: inherit;
} */
/* .MuiTableCell-alignRight {
  text-align: right;
  flex-direction: row-reverse;
  border: 1px solid;
} */

.flagDes {
  height: 115px;
  margin-top: 10px;
}

/* .mainTR
{
  /* padding: 20px 20px; */
/* } */
.mainTR td {
  padding-top: 10px;
}

.mainTR .tdflag {
  padding-left: 20px;
  padding-right: 10px;
  width: 10px;
}
.mainTR .tdCurr {
  /* padding-left: 20px; */
  padding-right: 10px;
  font-size: 20px;
  font-weight: 700;
  font-family: sans-serif;
  text-transform: uppercase;
  text-align: left;
  color: black;
}
.mainTR .tdTo {
  padding-left: 20px;
  padding-right: 10px;
}
.mainTR .td7 {
  padding-left: 20px;
  padding-right: 10px;
}

.currencyBGimg {
  background-color: #f6f7fa;
}

.cardOver {
  background: none !important;
  border: none !important;
}

.breadcrumbs {
  margin-bottom: 0em !important;
}

.outerdiv {
  /* margin: 85px 20px; */
  /* padding: 10px 90px; */
  /* border: 1px solid rgba(0,0,0,.125); */
  border-radius: 0.25rem;
}

.bodyTxtBold1 {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 18px;
  color: #0060a3;
  text-align: center;
}

.rateTable table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.rateTable td,
.rateTable th {
  /* border: 1px solid #dddddd; */
  text-align: left;
  padding: 8px;
}

.rateTable tr:nth-child(even) {
  /* background-color: #cae1ff; */
  background-color: rgba(202, 225, 255, 0.5) !important;
}

.tableHeading {
  background-color: #104e8b !important;
  color: white !important;
}

.tableHeading th {
  color: white !important;
}

.curr_Table {
  text-align: center;
  /* margin: 0px 265px; */
}

.tdCurrC {
  /* padding-top:15px; */
  font-size: 20px;
  font-weight: 700;
  font-family: sans-serif;
  /* text-transform: uppercase; */
  text-align: center;
  color: #003b6d;
}

.tdCurrC h4 {
  padding-top: 10px;
  font-size: 20px;
  font-weight: 700;
  font-family: sans-serif;
  /* text-transform: uppercase; */
  text-align: center;
}

.tdCurrN {
  /* padding-top:15px; */
  font-size: 20px;
  font-weight: 700;
  font-family: sans-serif;
  /* text-transform: uppercase; */
  text-align: center;
  color: #ff4500;
}

.tdCurrN h4 {
  padding-top: 10px;
  font-size: 20px;
  font-weight: 700;
  font-family: sans-serif;
  /* text-transform: uppercase; */
  text-align: center;
}

.card-body {
  padding: 0px !important;
  margin-top: 0px;
}

/* .card-header {
  padding: 0px !important;
} */

.marquee {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.fl-left {
  float: left;
}
.divStyle {
  /* border: 1px solid black; */
  border-radius: 10px;
  margin: 20px;
  height: 100px;
  color: #fff;
  background-image: linear-gradient(
    to right,
    #47e3d5,
    #29d6d8,
    #0ac9d9,
    #04bbd8,
    #1eadd3
  );
}
.divStyleAlt {
  /* border: 1px solid black; */
  border-radius: 10px;
  margin: 20px;
  height: 100px;
  color: #fff;
  background-image: linear-gradient(
    to right,
    #754efe,
    #7668ff,
    #7c7eff,
    #8793ff,
    #96a6fe
  );
}
.currMainDiv {
  margin-left: 40px;
}

.currRow {
  padding: 0px 200px;
}

.arrowDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 255px;
}

.MuiTableBody-root .MuiTableRow-root:hover {
  background-color: lightgray !important;
}

.divEx {
  float: left;
  margin-left: 95px !important;
  margin-right: 58px !important;
}

.divEx img {
  width: 149px;
  border-radius: 50px;
  margin-top: 20px;
  margin-left: 10px;
}

.tdselectedCurrC {
  color: darkcyan;
}

.alCenter {
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .exchange_h4 {
    float: left !important;
    line-height: 1em;
  }

  .curr_Table {
    margin: 0px;
  }

  .currRow {
    padding: 0px;
  }

  .currMainDiv {
    margin-left: 0px;
  }

  .footer .footer-top .footer-links {
    padding-left: 10px !important;
    margin-bottom: 0px !important;
  }
  .footer .social-links a i {
    color: #012970 !important;
  }
  .footer .footer-top .footer-info {
    margin-bottom: 0px !important;
  }
  .footer-links {
    color: #012970 !important;
  }
  .footer .footer-top .footer-links ul i {
    color: #012970 !important;
  }
  .footer .footer-top .footer-links ul a {
    color: #012970 !important;
  }
  .footer .footer-top {
    border: none !important;
  }

  .outerdiv {
    padding: 10px 0px;
  }

  .divEx {
    float: left;
    margin-left: 5px !important;
    margin-right: 0px !important;
    /* width: 100%; */
  }

  .divEx img {
    margin-left: 0px;
    width: 85px;
    border-radius: 25px;
  }

  /* .currency_row {
    float: left;
    width: 100%;
    margin-top: 10px !important;
  } */
  .bordercss {
    border: 3px solid black;
  }
  .card-body {
    margin-top: 0px;
  }
  .page_header_slider {
    margin-top: 4.3em !important;
  }
}
.currency_column {
  padding: 0px;
}

.topHunTable .MuiTableCell-root {
  padding: 8px;
}

.currency_slider .react-slideshow-container .default-nav:last-of-type {
  margin-left: 8px !important;
  margin-right: -1em !important;
  background: #d5d8dc;
}
.currency_slider .react-slideshow-container {
  background: #fff;
  /* border-radius: 20px; */
  margin-bottom: 2.5em;
  line-height: 0.1;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.currency_slider .react-slideshow-container .default-nav:first-of-type {
  margin-right: 12px !important;
  border-right: 0;
  border-top: 0;
  background: #d5d8dc;
  margin-left: -17px !important;
}
.currency_mobile_view_table {
  display: none;
}
.page_header_slider {
  margin-top: 1.5em;
  background: lavender;
}
