.sum_analysis th {
  text-align: center;
  font-weight: bold;
}
.sum_analysis tbody,
.sum_analysis td,
.sum_analysis tfoot,
.sum_analysis th,
.sum_analysis thead,
.sum_analysis tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border: 1px solid gray !important;
}

.sum_analysis td,
.sum_analysis tfoot {
  text-align: right;
}

.sum_analysis tr:nth-child(even) {
  background-color: #cae1ff;
  background-color: rgba(202, 225, 255, 0.5) !important;
}
