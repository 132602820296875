.MuiOutlinedInput-input {
  padding: 10px 10px !important;
}
.cardCss {
  /* height: 350px !important;
  margin-top: 70px !important; */
  border-radius: 30px;
}
.imgCss {
  height: 300px;
  padding-left: 65px;
  padding-top: 20px;
}
.ptsCardCss {
  margin-top: 15px;
  margin-right: 60px;
  margin-bottom: 15px;
  margin-left: 60px;
}
.loginCss {
  font-size: 27px;
  font-weight: bold;
  text-align: center;
}

.sociallbl {
  float: left;
  padding: 20px 0px;
  margin: 20px 0px;
}
.socialicons {
  width: fit-content;
  margin: 5px;
  /* float: left; */
  border-radius: 2px;
}
.socialicons img {
  width: 50px;
  height: 50px;
}
.socialdiv {
  margin-left: 20%;
}

input:focus {
  background: #f3f8fe;
}

.kwmIMH {
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
  font-weight: normal;
  font-size: 12px;
  height: 40px;
  line-height: normal;
  color: rgb(38, 46, 58);
  border: 1px solid rgba(38, 46, 58, 0.2);
  background: rgba(38, 46, 58, 0.05);
  transition: border 0.2s ease 0s, background 0.2s ease 0s;
  caret-color: rgb(13, 110, 241);
}

.paperCss {
  /* height: 680px; */
  width: 375px;
  display: flex;
  justify-content: center;
  margin: 20px;
  margin-left: 33%;
}
.outerDivCss {
  padding: 12px;
}
.btncontinue {
  width: 100%;
  background-color: #0c63d9;
}
.forgotPassCss {
  padding-left: 0px;
}

.orCss {
  /* padding-left: 85px; */
  margin-top: 20px;
  margin-bottom: 0px;
  text-align: center;
}
.invalid input:required:invalid {
  background: #be4c54;
}
.invalid input:required:valid {
  background: #17d654;
}
.textFieldRow {
  padding: 2em 1em 1em 2em;
}

.fb_google img {
  width: 50px;
  height: 50px;
}

.form_header {
  font-weight: bold;
  border-bottom: 1px solid #d5d8dc;
  color: #3772aa;
}
.form_header_address {
  font-weight: bold;
  border-bottom: 1px solid #d5d8dc;
  color: #3772aa;
}
.modalContain .modal-title {
  color: #000;
}
