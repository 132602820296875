.qualityRatingHeading {
  font-weight: bold;
  color: #1a5276;
  text-align: center;
  text-decoration: underline;
}

.investment_acceptance tbody,
.investment_acceptance td,
.investment_acceptance tfoot,
.investment_acceptance th,
.investment_acceptance thead,
.investment_acceptance tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border: 1px solid gray !important;
  padding: 11px 0px 0px 5px;
  font-size: 0.9em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.investment_acceptance td,
.investment_acceptance tfoot {
  text-align: right;
  padding: 0px 5px 0px 0px;
}
.investment_acceptance th,
.investment_acceptance thead {
  width: 85% !important;
}
.investment_acceptance tr:nth-child(even) {
  background: rgb(202, 225, 255);
}
.investment_acceptance tr:nth-child(odd) {
  background: #fff;
}
.wqa_modal {
  height: 33em;
  overflow: auto;
}
