.price_tag {
  font-weight: bold;
  color: #0060a3;
}

.bodyTxtBold_iv_rpt {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #0060a3;
}
.bodyTxtBold_iv_rpt_option {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #566573;
}
.IV_reports p {
  font-size: 0.9em;
  color: #566573;
}
.pts_list_row {
  border-bottom: 1px solid #d6eaf8;
  padding: 4px;
}
.report_list {
  text-decoration: underline;
  color: #0060a3;
  font-weight: 600;
  font-size: 0.8em;
}

.report_content {
  font-size: 0.9em;
  color: #566573;
  border-collapse: collapse !important;
}
.ind_content_report {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px,
    rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
  background-color: rgb(255, 255, 255);
  padding: 10px;
}
.ind_report .card-header {
  padding: 5px !important;
  background: rgb(22, 160, 133) none repeat scroll 0% 0%;
  color: #fff;
}
.company_report_sample {
  font-weight: bold;
  color: #0060a3;
  text-decoration: underline;
}
.pts_mobile_view {
  margin-top: 3em;
}
