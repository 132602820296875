th {
  text-align: inherit;
  text-align: -webkit-match-parent;
  font-weight: 100;
}

.financial_analysis tbody,
.financial_analysis td,
.financial_analysis tfoot,
.financial_analysis th,
.financial_analysis thead,
.financial_analysis tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border: 1px solid gray !important;
  padding: 0px 0px 0px 5px;
}

.financial_analysis td,
.financial_analysis tfoot {
  text-align: right;
  padding: 0px 5px 0px 0px;
}
