.MuiInputLabel-outlined {
  z-index: 1;
  transform: translate(14px, 20px) scale(1);
  pointer-events: none;
  margin-top: -0.8em;
}
.download_button {
  background: #99b6df;
  background: -webkit-gradient(
    linear,
    0 0,
    0 bottom,
    from(#99b6df),
    to(#638ec8)
  );
  background: -moz-linear-gradient(#99b6df, #638ec8);
  background: linear-gradient(#99b6df, #638ec8);
  border: solid 1px #6d94ce;
  border-bottom: solid 3px #3867ac;
  box-shadow: inset 0 0 0 1px #bbcfeb;
  color: #fff;
  text-shadow: 0 1px 0 #3c61ab;
}
.aboutusSec .img1 {
  width: 230px;
  margin: -80px 0px 0px 35px;
  height: 230px;
  border-radius: 10px;
}
.aboutusSec .img2 {
  width: 230px;
  margin: 80px 0px 0px 0px;
  height: 230px;
  border-radius: 10px;
}
.aboutusSec .img3 {
  width: 230px;
  margin: -70px 0px 0px 35px;
  height: 230px;
  border-radius: 10px;
}
.aboutusSec .img4 {
  width: 230px;
  margin: 85px 0px 0px 0px;
  height: 230px;
  border-radius: 10px;
}
.aboutusSec .img5 {
  width: 230px;
  margin: -58px 0px 0px 35px;
  height: 230px;
  border-radius: 10px;
}
.about h3 {
  /* color: #00112a; */
  color: #fff;
  text-transform: uppercase;
  font-size: 38px;
  line-height: 55px;
  font-weight: 700;
  text-align: center;
  font-family: verdana, Arial, Helvetica, sans-serif;
}
.aboutusSec {
  background-color: cadetblue;
  height: 723px;
  margin-top: 100px;
}

.heading {
  text-align: center;
  color: #454343;
  font-size: 30px;
  font-weight: 700;
  position: relative;
  margin-bottom: 70px;
  text-transform: uppercase;
  z-index: 999;
}
.white-heading {
  color: #ffffff;
}
.heading:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  height: 40px;
  width: 180px;
  border-radius: 4px;
  transform: translateX(-50%);
  /* background: url(img/heading-line.png); */
  background-repeat: no-repeat;
  background-position: center;
}
.white-heading:after {
  background: url(https://i.ibb.co/d7tSD1R/heading-line-white.png);
  background-repeat: no-repeat;
  background-position: center;
}

.heading span {
  font-size: 18px;
  display: block;
  font-weight: 500;
}
.white-heading span {
  color: #ffffff;
}
/*-----Testimonial-------*/

.testimonial:after {
  position: absolute;
  top: -0 !important;
  left: 0;
  content: " ";
  /* background: url(img/testimonial.bg-top.png); */
  background-size: 100% 100px;
  width: 100%;
  height: 100px;
  float: left;
  z-index: 99;
}

.testimonial {
  min-height: 375px;
  position: relative;
  background: url(https://i.ibb.co/PTJDkgb/testimonials.jpg);
  padding-top: 50px;
  padding-bottom: 50px;
  background-position: center;
  background-size: cover;
}

.slider_pts {
  background: url(../corporateInformationHome/assets/img/slider1.jpg);
  height: 20em;
  /* background-repeat: no-repeat;
  background-origin: border-box; */
  width: 100%;
}
.CheckButton {
  background: rgb(242, 109, 33);
  color: #fff;
  padding: 10px;
}
#testimonial4 .carousel-inner:hover {
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
#testimonial4 .carousel-inner:active {
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
#testimonial4 .carousel-inner .item {
  overflow: hidden;
}

.testimonial4_indicators .carousel-indicators {
  left: 0;
  margin: 0;
  width: 100%;
  font-size: 0;
  height: 20px;
  bottom: 15px;
  padding: 0 5px;
  cursor: e-resize;
  overflow-x: auto;
  overflow-y: hidden;
  position: absolute;
  text-align: center;
  white-space: nowrap;
}
.testimonial4_indicators .carousel-indicators li {
  padding: 0;
  width: 14px;
  height: 14px;
  border: none;
  text-indent: 0;
  margin: 2px 3px;
  cursor: pointer;
  display: inline-block;
  background: #ffffff;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
.testimonial4_indicators .carousel-indicators .active {
  padding: 0;
  width: 14px;
  height: 14px;
  border: none;
  margin: 2px 3px;
  background-color: #9dd3af;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
.testimonial4_indicators .carousel-indicators::-webkit-scrollbar {
  height: 3px;
}
.testimonial4_indicators .carousel-indicators::-webkit-scrollbar-thumb {
  background: #eeeeee;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.testimonial4_control_button .carousel-control {
  top: 175px;
  opacity: 1;
  width: 40px;
  bottom: auto;
  height: 40px;
  font-size: 10px;
  cursor: pointer;
  font-weight: 700;
  overflow: hidden;
  line-height: 38px;
  text-shadow: none;
  text-align: center;
  position: absolute;
  background: transparent;
  border: 2px solid #ffffff;
  text-transform: uppercase;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.6s cubic-bezier(0.3, 1, 0, 1);
  transition: all 0.6s cubic-bezier(0.3, 1, 0, 1);
}
.testimonial4_control_button .carousel-control.left {
  left: 7%;
  top: 50%;
  right: auto;
}
.testimonial4_control_button .carousel-control.right {
  right: 7%;
  top: 50%;
  left: auto;
}
.testimonial4_control_button .carousel-control.left:hover,
.testimonial4_control_button .carousel-control.right:hover {
  color: #000;
  background: #fff;
  border: 2px solid #fff;
}

.testimonial4_header {
  top: 0;
  left: 0;
  bottom: 0;
  width: 550px;
  display: block;
  margin: 30px auto;
  text-align: center;
  position: relative;
}
.testimonial4_header h4 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
  position: relative;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.testimonial4_slide {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 70%;
  margin: auto;
  padding: 20px;
  position: relative;
  text-align: center;
}
.testimonial4_slide img {
  top: 0;
  left: 0;
  right: 0;
  width: 136px;
  height: 136px;
  margin: auto;
  display: block;
  color: #f2f2f2;
  font-size: 18px;
  line-height: 46px;
  text-align: center;
  position: relative;
  border-radius: 50%;
  box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  -o-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  -webkit-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
}
.testimonial4_slide p {
  color: #ffffff;
  font-size: 20px;
  line-height: 1.4;
  margin: 40px 0 20px 0;
}
.testimonial4_slide h4 {
  color: #ffffff;
  font-size: 22px;
}

.testimonial .carousel {
  padding-bottom: 50px;
}
.testimonial .carousel-control-next-icon,
.testimonial .carousel-control-prev-icon {
  width: 35px;
  height: 35px;
}
/* ------testimonial  close-------*/
.footerImage {
  background: transparent url(./assets/img/graph1.png) no-repeat !important;
  background-size: cover !important;
}

.footerFont {
  color: #fff !important;
}

.footer .footer-top .footer-info .logo {
  line-height: 0;
  margin-bottom: 15px;
}
.footer .footer-top h4 {
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}
.footerh4 {
  text-transform: none !important;
  font-size: 25px !important;
  font-family: system-ui !important;
  font-weight: 700 !important;
  color: #fff !important;
}
.footerh4 span {
  color: #0094d9 !important;
  text-transform: none !important;
  font-size: 25px !important;
  font-family: system-ui !important;
  font-weight: 700 !important;
}
.footer .footer-top .ptsfooter-links {
  margin-bottom: 30px;
  padding-left: 100px;
}
.footer .footer-top .ptsfooter-links ul i {
  padding-right: 2px;
  color: #d0d4fc;
  font-size: 12px;
  line-height: 0;
}
.footer .footer-top .ptsfooter-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
  color: #d0d4fc;
}
.footer .footer-top .ptsfooter-links ul li i a {
  color: #d0d4fc;
}
a {
  color: #d0d4fc;
  text-decoration: none;
}

.footer .footer-top .footer-links {
  margin-bottom: 30px;
  padding-left: 100px;
}
.footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #d0d4fc;
  font-size: 12px;
  line-height: 0;
}
.footer .social-links a i {
  color: #ffffff;
}
.footer .footer-top .footer-info {
  margin-bottom: 30px;
}
.fooCopyR {
  padding: 0px 104px;
  background-color: #23262a;
}
.copyrightContent {
  text-align: center;
  color: cornflowerblue;
}
.fooCopyR span {
  color: #fff;
}
.srch .searchTicker {
  padding: 20px 0;
  /* background: #f6f9ff; */
  border-top: 1px solid transparent;
}

/* <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap" rel="stylesheet"> */
/* *,
*:after,
*:before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: default;
}

html {
  width: 100%;
  height: auto;
}

body {
  font-family: "Montserrat", sans-serif !important;
  width: 100%;
  height: auto;
  font-size: 14px;

  background: #fff;
  overflow: hidden;
} */
.testim {
  width: 100%;

  top: 50%;
  /* -webkit-transform: translatey(-50%);
  -moz-transform: translatey(-50%);
  -ms-transform: translatey(-50%);
  -o-transform: translatey(-50%); */
  /* transform: translatey(-50%); */
}

.testim .wrap {
  position: relative;
  width: 100%;
  max-width: 1020px;
  padding: 40px 20px;
  margin: auto;
}

.testim .arrow {
  display: block;
  position: absolute;
  color: #333;
  cursor: pointer;
  font-size: 2em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 5px;
  z-index: 22222222;
}

.testim .arrow:before {
  cursor: pointer;
}

.testim .arrow:hover {
  color: green;
}

.testim .arrow.left {
  left: 70px;
}

.testim .arrow.right {
  right: 70px;
}

.testim .dots {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 60px;
  left: 0;
  display: block;
  z-index: 3333;
  height: 12px;
}

.testim .dots .dot {
  list-style-type: none;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid green;
  margin: 0 10px;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
}

.testim .dots .dot.active,
.testim .dots .dot:hover {
  background: green;
  border-color: green;
}

.testim .dots .dot.active {
  -webkit-animation: testim-scale 0.5s ease-in-out forwards;
  -moz-animation: testim-scale 0.5s ease-in-out forwards;
  -ms-animation: testim-scale 0.5s ease-in-out forwards;
  -o-animation: testim-scale 0.5s ease-in-out forwards;
  animation: testim-scale 0.5s ease-in-out forwards;
}

.testim .cont {
  position: relative;
  overflow: hidden;
}

.testim .cont > div {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0 70px 0;
  opacity: 0;
}

.testim .cont > div.inactive {
  opacity: 1;
}

.testim .cont > div.active {
  position: relative;
  opacity: 1;
}

.testim .cont div .img img {
  display: block;
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 50%;
}

.testim .cont div h2 {
  color: green;
  font-size: 1em;
  margin: 15px 0;
}

.testim .cont div p {
  font-size: 1.15em;
  color: #333;
  width: 70%;
  margin: auto;
}

.testim .cont div.active .img img {
  -webkit-animation: testim-show 0.5s ease-in-out forwards;
  -moz-animation: testim-show 0.5s ease-in-out forwards;
  -ms-animation: testim-show 0.5s ease-in-out forwards;
  -o-animation: testim-show 0.5s ease-in-out forwards;
  animation: testim-show 0.5s ease-in-out forwards;
}

.testim .cont div.active h2 {
  -webkit-animation: testim-content-in 0.4s ease-in-out forwards;
  -moz-animation: testim-content-in 0.4s ease-in-out forwards;
  -ms-animation: testim-content-in 0.4s ease-in-out forwards;
  -o-animation: testim-content-in 0.4s ease-in-out forwards;
  animation: testim-content-in 0.4s ease-in-out forwards;
}

.testim .cont div.active p {
  -webkit-animation: testim-content-in 0.5s ease-in-out forwards;
  -moz-animation: testim-content-in 0.5s ease-in-out forwards;
  -ms-animation: testim-content-in 0.5s ease-in-out forwards;
  -o-animation: testim-content-in 0.5s ease-in-out forwards;
  animation: testim-content-in 0.5s ease-in-out forwards;
}

.testim .cont div.inactive .img img {
  -webkit-animation: testim-hide 0.5s ease-in-out forwards;
  -moz-animation: testim-hide 0.5s ease-in-out forwards;
  -ms-animation: testim-hide 0.5s ease-in-out forwards;
  -o-animation: testim-hide 0.5s ease-in-out forwards;
  animation: testim-hide 0.5s ease-in-out forwards;
}

.testim .cont div.inactive h2 {
  -webkit-animation: testim-content-out 0.4s ease-in-out forwards;
  -moz-animation: testim-content-out 0.4s ease-in-out forwards;
  -ms-animation: testim-content-out 0.4s ease-in-out forwards;
  -o-animation: testim-content-out 0.4s ease-in-out forwards;
  animation: testim-content-out 0.4s ease-in-out forwards;
}

.testim .cont div.inactive p {
  -webkit-animation: testim-content-out 0.5s ease-in-out forwards;
  -moz-animation: testim-content-out 0.5s ease-in-out forwards;
  -ms-animation: testim-content-out 0.5s ease-in-out forwards;
  -o-animation: testim-content-out 0.5s ease-in-out forwards;
  animation: testim-content-out 0.5s ease-in-out forwards;
}

@-webkit-keyframes testim-scale {
  0% {
    -webkit-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -webkit-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -webkit-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -webkit-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-moz-keyframes testim-scale {
  0% {
    -moz-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -moz-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -moz-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -moz-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-ms-keyframes testim-scale {
  0% {
    -ms-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -ms-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -ms-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -ms-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-o-keyframes testim-scale {
  0% {
    -o-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -o-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -o-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -o-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@keyframes testim-scale {
  0% {
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-webkit-keyframes testim-content-in {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-moz-keyframes testim-content-in {
  from {
    opacity: 0;
    -moz-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
}

@-ms-keyframes testim-content-in {
  from {
    opacity: 0;
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@-o-keyframes testim-content-in {
  from {
    opacity: 0;
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes testim-content-in {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes testim-content-out {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-moz-keyframes testim-content-out {
  from {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    -moz-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-ms-keyframes testim-content-out {
  from {
    opacity: 1;
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-o-keyframes testim-content-out {
  from {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@keyframes testim-content-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@-webkit-keyframes testim-show {
  from {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes testim-show {
  from {
    opacity: 0;
    -moz-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
}

@-ms-keyframes testim-show {
  from {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@-o-keyframes testim-show {
  from {
    opacity: 0;
    -o-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes testim-show {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes testim-hide {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@-moz-keyframes testim-hide {
  from {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -moz-transform: scale(0);
    transform: scale(0);
  }
}

@-ms-keyframes testim-hide {
  from {
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
  }
}

@-o-keyframes testim-hide {
  from {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -o-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes testim-hide {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}

@media all and (max-width: 300px) {
  body {
    font-size: 14px;
  }
}

@media all and (max-width: 500px) {
  .testim .arrow {
    font-size: 1.5em;
  }

  .testim .cont div p {
    line-height: 25px;
  }
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 1200px;
  margin: auto;
  padding: 10px;
  text-align: center;
}

/* h2 {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 120%;
  margin-bottom: 20px;
  color: #fff;
} */
.portfolio_section {
  margin: 20px 0px;
  padding-bottom: 20px;
  text-align: center;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  color: #fff;
}
.portfolio_section h2 {
  /* margin-top:20px; */
  text-align: center;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 2em;
}
.filter-menu {
  margin-bottom: 20px;
}
.filter-menu li {
  display: inline-block;
  padding: 10px 18px;
  background: #eb4d4b;
  color: #fff;
  cursor: pointer;
}
.filter-menu li:hover,
.filter-menu li.current {
  background: #ff7979;
}

.filter-item li {
  width: 50%;
  padding: 2px;
  float: left;
}

.filter-item li.active {
  width: 50%;
  padding: 2px;
  transition: all 0.5s ease;
}

.filter-item li.delete {
  width: 0%;
  padding: 0;
  transition: all 0.5s ease;
}

.filter-item img {
  display: block;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .filter-item li.active,
  .filter-item li {
    width: 33.33%;
  }

  h2 {
    font-size: 190%;
  }
}
@media screen and (min-width: 1280px) {
  .filter-item li.active,
  .filter-item li {
    width: 25%;
  }

  h2 {
    font-size: 270%;
  }
}

@import url("https://fonts.googleapis.com/css?family=Oswald");

.grid {
  list-style: none;
  margin-left: -40px;
}

.gc {
  box-sizing: border-box;
  display: inline-block;
  margin-right: -0.25em;
  min-height: 1px;
  padding-left: 40px;
  vertical-align: top;
}

.gc--1-of-3 {
  width: 33.33333%;
}

.gc--2-of-3 {
  width: 66.66666%;
}

.naccs {
  position: relative;
}

.naccs .menu div {
  padding: 15px 20px 15px 40px;
  margin-bottom: 20px;
  color: #fff;
  background: darkslategrey;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
  vertical-align: middle;
  font-weight: 700;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
  text-align: left;
}

.naccs .menu div:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.naccs .menu div span.light {
  height: 10px;
  width: 10px;
  position: absolute;
  top: 24px;
  left: 15px;
  background-color: transparent;
  border-radius: 100%;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.naccs .menu div.active span.light {
  background-color: #fbc02d;
  left: 0;
  height: 100%;
  width: 3px;
  top: 0;
  border-radius: 0;
}

.naccs .menu div.active {
  color: #fbc02d;
  padding: 15px 20px 15px 20px;
}

ul.nacc {
  position: relative;
  height: 0px;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

ul.nacc li {
  opacity: 0;
  transform: translateX(50px);
  position: absolute;
  list-style: none;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

ul.nacc li.active {
  transition-delay: 0.3s;
  z-index: 2;
  opacity: 1;
  transform: translateX(0px);
}

ul.nacc li p {
  margin: 0;
}
.advertisement {
  height: 2em !important;
}
/* .aboutUsContent {
} */
.about p {
  margin: 15px 0 30px 0;
  line-height: 24px;
  color: #ffff;
}
.footer .footer-top .ptsfooter-links h4 {
  color: #fff;
}
.text-md-start h4 {
  color: #fff !important;
}
