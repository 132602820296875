/* .cusTabs {
  border-bottom: 1px solid #dee2e6;
  float: right;
  font-weight: bold;
} */
.tab-content {
  margin-top: 5em;
}
/* .cusTabs .nav-item.show .nav-link,
    .cusTabs .nav-link.active {
      color: #495057;
      background-color: #fff;
      border-color: transparent !important;
      border-bottom: 2px solid #013289 !important;
    } */

.topHunTable .MuiTableRow-root {
  border: none !important;
}

.topHunTable .MuiTableRow-root:nth-child(odd) {
  background-color: rgb(255, 255, 255) !important;
  border: none !important;
}

.topHunTable .MuiTableRow-root:not(:nth-child(odd)) {
  border: none !important;
}

.MuiTableCell-root {
  border: none !important;
}

.cusTabs {
  border-bottom: 1px solid #dee2e6;
  float: right;
  font-weight: bold;
}
.tab-content {
  margin-top: 5em;
}
.cusTabs .nav-item.show .nav-link,
.cusTabs .nav-link.active {
  /* color: #495057; */
  background-color: #fff;
  border-color: transparent !important;
  border-bottom: 2px solid #013289 !important;
}

.cusTabs .nav-link {
  background-color: mediumaquamarine;
  margin-left: 35px;
  color: #fff;
}

.cusTabs .nav-link.active {
  /* background-color: mediumaquamarine; */
  margin-left: 35px;
  color: mediumaquamarine !important;
  border-bottom: 2px solid mediumaquamarine !important;
}

.cusTabs {
  border-bottom: 1px solid #dee2e6;
  margin-top: 10px;
}

/* .MuiTableRow-hover :hover
    {
      background-color: silver !important;
    } */

.topHunTable .MuiTableRow-root:hover {
  background-color: lightgray !important;
}

.MuiPaper-root {
  border: 2px solid F8F8F8;
  box-shadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" !important ;
}
.MuiTableSortLabel-root,
.MuiTableSortLabel-root div {
  cursor: default !important;
}

.MuiTableSortLabel-root svg {
  cursor: default;
}
.MuiTableHead-root {
  cursor: default;
}
.MuiSvgIcon-root {
  cursor: default;
}

/* .MuiPaper-root
    {
    border: 2px solid F8F8F8;
    box-shadow:
    5px 5px 8px blue, 10px 10px 8px red, 15px 15px 8px green !important;
    } */

.card {
  border: none !important;
}

/* .advanceFeature {
    background-color: #e7f5f3 !important;
  } */

.exchange_h6 {
  line-height: 0.3em;
  font-weight: bolder;
  text-align: left;
  padding-top: 20px;
  padding-left: 20px;
}

.rowAlign {
  padding-left: 70px;
}
.advanceDivStyle {
  font-family: Arial;
  font-size: x-large;
  font-weight: bold;
}

.advdivstyle {
  height: 100px;
  padding: 0em 4em;
}
.advDivStyl {
  font-family: verdana;
  font-size: medium;
  font-weight: bold;
}
.advFeatureDiv {
  background-color: #e7f5f3 !important;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.tdCSS {
  color: #4682b4;
}
.tdstyleCss {
  padding-left: 60px;
}
.tableCssStyle {
  margin-top: 15px;
}

@media (max-width: 768px) {
  .advanceDivStyle {
    font-size: 18px;
  }

  .tableMobCss {
    padding-left: 40px !important;
    margin-bottom: 12px !important;
    margin-top: -30px !important;
  }

  .mobStyle {
    margin-left: -35px !important;
  }
  .btnMobCss {
    margin-right: 220px !important;
    padding: 10px 25px 10px 25px !important;
  }
  .top_100_filter_slide .react-slideshow-container {
    background: #eaf2f8;
    width: 106.95% !important;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  }
  .selected_criteria{
    display: flex;
      width: 19% !important;
  }
  .rank_methodology{
    margin-top: 0em !important;
  }
  .top_100_filter_table_row .pts_mobile_view{
    margin-top: 5em !important;
  }
}
.filter_subheading {
  font-style: italic;
  font-size: 0.9em;
}
.top_100_filter_slide {
  padding: 0;
}
.top_100_filter_slide .react-slideshow-container {
  background: #eaf2f8;
  width: 101.60%;
  /* line-height: 1; */
  /* border-radius: 20px;
  margin-bottom: -1.5em;
  line-height: 0.1;
  padding: 10px;*/
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
.PrivateNotchedOutline-root-1 {
  top: -1px !important;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  border-style: solid;
  border-width: 1px;
  border-radius: inherit;
  pointer-events: none;
}
.top_100_filter_table_row .MuiTableRow-root:nth-child(odd) {
  background-color: rgb(255, 255, 255) !important;
  border: none !important;
}
.selected_criteria{
  display: flex;
    width: 27%;
}
.rank_methodology{
  margin-top: -1em;
}
.top_100_filter_table_row .pts_mobile_view{
  margin-top: 2.5em;
}