.bold_text {
  color: #0060a3;
  font-size: 2em;
  font-weight: bold;
}
.bold_text2 {
  font-size: 1.5em;
  color: #0060a3;
}
.simple_btn {
  background: linear-gradient(#f6c696, #e9893d) !important;
}
.price_tag {
  font-weight: bold;
  color: #0060a3;
}

.bodyTxtBold1 {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #0060a3;
}
.Cmp_reports p {
  font-size: 0.9em;
}
a.button.rss {
  background: #f6c696;
  background: -webkit-gradient(
    linear,
    0 0,
    0 bottom,
    from(#f6c696),
    to(#e9893d)
  );
  background: -moz-linear-gradient(#f6c696, #e9893d);
  background: linear-gradient(#f6c696, #e9893d);
  border: solid 1px #a1681b;
  border-bottom: solid 3px #a1671d;
  box-shadow: inset 0 0 0 1px #f1bb8f;
  color: #fff;
  text-shadow: 0 1px 0 #a1671d;
}

a.button.rss:hover {
  background: #e9893d;
  background: -webkit-gradient(
    linear,
    0 0,
    0 bottom,
    from(#e9893d),
    to(#f6c696)
  );
  background: -moz-linear-gradient(#e9893d, #f6c696);
  background: linear-gradient(#e9893d, #f6c696);
  border: solid 1px #a1681b;
  border-bottom: solid 3px #a1671d;
  box-shadow: inset 0 0 0 1px #f1bb8f;
}
.quality_rating {
  list-style: none;
  /* color: #0060a3; */
  color: rgb(242, 109, 33);
  text-decoration: underline;
  font-weight: bold;
  margin: 0px 0px 0.3em -0em;
  cursor: pointer;
}
.company_report_sample {
  font-weight: bold;
  color: #0060a3;
  text-decoration: underline;
}
.company_report_list {
  list-style-type: disc;
}
.company_report_list {
  color: #0060a3;
  font-weight: 600;
  font-size: 0.8em;
}
