.generic_search_box {
  width: 60%;
}
@media (max-width: 768px) {
  .generic_search_box {
    width: 45%;
  }
  /* .generic_search_box_home {
    width: 60%;
  } */
}
/* .generic_search_box_home {
  width: 60%;
} */
