/* .validity {
  font-size: 15px !important;
  font-weight: 700 !important;
  color: "#fff" !important;
  float: left !important;
  vertical-align: middle !important;
  margin-left: 60px;
  margin-top: 15px;
}
.single {
  margin-top: 25px;
}
.yellowBtn {
  background-color: #fce08d !important;
  border-radius: 5px !important;
  border: none !important;
  color: #fff !important;
  margin-bottom: 15px;
  position: relative;
  margin-top: 210px;
}
.blueBtn {
  background-color: #9ce0e3 !important;
  border-radius: 5px !important;
  border: none !important;
  color: #fff !important;
  margin-bottom: 15px;
  position: relative;
  margin-top: 140px;

}
.orangeBtn {
  background-color: #ffaf7a !important;
  border-radius: 5px !important;
  border: none !important;
  color: #fff !important;
  margin-bottom: 15px;

}
.box {
  padding: 0px !important;
}
.innerDiv {
  height: 150px !important;
}
.ulCss {
  font-size: 14px !important;
  margin-top: 10px;

}
.ulCss li {
  color: black;
  font-style: normal;
  line-height: 25px;
}
.innerDiv h3 {
  color: rgb(255, 255, 255);
  padding-top: 10px;
  font-size: 25px;
}
.pricing img {
  padding: 0px 0px;
  width: 100px;
  height: 70px;
  margin-left: 30px;
}
.dvPriceImg {
  float: left;
  margin-left: 10px;
}

.paracss {
  float: left;
  font-size: 16px;
  margin-top: 20px;
}


.btnSample {
  margin-top: 22px;
  font-size: 10px;
  margin-left: 15px;

  background-color: #012970;
}
.box {
  height: 530px;
  border-radius: 10px !important;
} */
@import url(https://fonts.googleapis.com/css?family=Lato:400,700);
.textDiv {
  float: left;
  margin-left: 20px;
  margin-top: 25px;
}
body {
  padding: 0;
  margin: 0;
}
.contcss {
  margin-top: -5em;
}
#price {
  text-align: center;
}

.plan {
  display: inline-block;
  margin: 10px 1%;
  font-family: "Lato", Arial, sans-serif;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.plan .standard :hover {
  transform: scale(1.1);
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}
.plan-inner {
  background: #fff;
  margin: 0 auto;
  min-width: 280px;
  max-width: 100%;
  position: relative;
  height: 31.5em;
}

.entry-title {
  background: #53cfe9;
  height: 116px;
  position: relative;
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
}

.entry-title > h3 {
  background: #20bada;
  font-size: 20px;
  padding: 5px 0;
  font-weight: 700;
  margin: 0;
}
.price {
  border-bottom: 1px solid #abb2b9;
  border-top: 1px solid #abb2b9;
}
.entry-title .price {
  position: absolute;
  bottom: -25px;
  background: #20bada;
  height: 95px;
  width: 95px;
  margin: 0 auto;
  left: 0;
  right: 0;
  overflow: hidden;
  border-radius: 50px;
  border: 5px solid #fff;
  line-height: 80px;
  font-size: 28px;
  font-weight: 700;
}

.price span {
  position: absolute;
  font-size: 23px;
  bottom: 4px;
  left: 30px;
  font-weight: 400;
}

.entry-content {
  color: #323232;
}

.entry-content ul {
  margin: -13;
  padding: 0;
  list-style: none;
  text-align: center;
}

.entry-content li {
  padding: 5px 0;
}

.entry-content li:last-child {
  border: none;
}

.btn_plan {
  padding: 0.8em 0;
  text-align: center;
  margin: -1em 0 0 0;
  display: inline-block;
}

.btn_plan a {
  background: #dd4b5e;
  padding: 7px 16px;
  color: #fff !important;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
}
.btn_plan1 {
  padding: 0.8em 0;
  text-align: center;
  margin: -1em 0 0 0;
  display: inline-block;
}
.btn_plan1 a {
  background: #53cfe9;
  padding: 7px 16px;
  color: #fff !important;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
}

.btn_plan2 {
  padding: 0.8em 0;
  text-align: center;
  margin: -1em 0 0 0;
  display: inline-block;
}
.btn_plan2 a {
  background: #53cfe9;
  padding: 7px 16px;
  color: #fff !important;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
}
.basic .entry-title {
  background: #75ddd9;
}

.basic .entry-title > h3 {
  background: #44cbc6;
}

.basic .price {
  background: #44cbc6;
}

.standard .entry-title {
  background: #4484c1;
}

.standard .entry-title > h3 {
  background: #3772aa;
}

.standard .price {
  background: #3772aa;
}

.ultimite .entry-title > h3 {
  background: #dd4b5e;
}

.ultimite .entry-title {
  background: #f75c70;
}

.ultimite .price {
  background: #dd4b5e;
}
.plan_card {
  border-top-left-radius: 1em !important;
  border-top-right-radius: 1em !important;
}
.plan_card_bottom {
  border-bottom-left-radius: 1em !important;
  border-bottom-right-radius: 1em !important;
}
.pts_mobile_view1 {
  margin-top: 0em;
}

.entry-title .price1 {
  position: absolute;
  bottom: -25px;
  background: #20bada;
  height: 95px;
  width: 95px;
  margin: 0 auto;
  left: 30px ;
  /* right: -153 !important; */
  overflow: hidden;
  border-radius: 50px;
  border: 5px solid #fff;
  line-height: 80px;
  font-size: 28px;
  font-weight: 700;
}
.entry-title .price2 {
  position: absolute;
  bottom: -25px;
  background: #20bada;
  height: 95px;
  width: 95px;
  margin: 0 auto;
  /* left: -153 !important; */
  right: 30 px ;
  overflow: hidden;
  border-radius: 50px;
  border: 5px solid #fff;
  line-height: 80px;
  font-size: 28px;
  font-weight: 700;
}
@media (max-width: 768px) {
  .entry-title .price2 {
    left: 0.5em !important;
  }
  .entry-title .price1 {
    left: 6em !important;
  }
.buy_now_btn2{
      /* text-align: center; */
      margin: -2em 0em 0 0 !important;
      /* float: right; */
      text-align: end !important;
      margin-left: -1em !important;
}
.buy_now_btn1{
  text-align: left !important;
    margin-left: -0.5em !important;
}
}
.buy_now_btn2{
  text-align: center;
 
}
.buy_now_btn1{
  text-align: center;
}