/* Loader Functionality */
.ptsloader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 4em;
  height: 4em;
  border: 1.1em solid rgba(53, 18, 209, 0.2);
  border-left: 1em solid #f26d21;
  border-radius: 50%;
  background: transparent;
  /* animation: load8 1.1s infinite linear; */
  transition: opacity 0.3s;
  /* position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1em solid back;
  border-left: 1em solid #f26d21;
  border-radius: 50%; */
  /* animation: load8 1.1s infinite linear; */
  /* transition: opacity 0.3s; */
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  /* -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); */
  opacity: 7000;
  z-index: 11111;
}

.ptsloaderHide {
  opacity: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0px !important;
}
page-content {
  background-color: #f6f7fa;
  padding-bottom: 2em;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.active,
.dot:hover {
  background-color: transparent !important;
}
