.balance_sheet tbody,
.balance_sheet td,
.balance_sheet tfoot,
.balance_sheet th,
.balance_sheet thead,
.balance_sheet tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border: 1px solid gray !important;
  padding: 10px 0px 0px 5px;
  font-size: 0.9em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.balance_sheet td,
.balance_sheet tfoot {
  text-align: right;
  padding: 0px 5px 0px 0px;
}

.balance_sheet tr:nth-child(even) {
  background: rgb(202, 225, 255);
}
.balance_sheet tr:nth-child(odd) {
  background: #fff;
}
