/* .pdf_company_Info {
  padding: 19px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: medium;
  padding-top: 0px;
}
.pdf_earn_dividend_table tbody,
.pdf_earn_dividend_table td,
.pdf_earn_dividend_table tfoot,
.pdf_earn_dividend_table th,
.pdf_earn_dividend_table thead,
.pdf_earn_dividend_table tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border: 1px solid #4f5050;
  padding: 13px;
} */

/* @media print {
  table {
    page-break-inside: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
} */
/* @media print {
  body{
      width: 21cm;
      height: 29.7cm;
      margin: 30mm 45mm 30mm 45mm;

 }
} */

/* .A4 {
  background: white;
  width: 21cm;
  height: 29.7cm;
  display: block;
  margin: 0 auto;
  padding: 10px 25px;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  box-sizing: border-box;
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }


}

@media print {
  body {
    margin: 0;
    padding: 0;
  }

  .A4 {
    box-shadow: none;
    margin: 0;
    width: auto;
    height: auto;
  }
} */

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  font: 12pt "Tahoma";
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.pdf_print {
  display: flex;
  min-width: 210mm;
  min-height: 297mm;
  /* width: 100%;
  height: 100%; */
  padding: 0 20mm;
  margin: 0 20mm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.ptsPage {
  display: block;
  page-break-before: always;
}
@page {
  size: A4;
  margin: auto;
}
@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  .printReport {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}

.earn_dividend tbody,
.earn_dividend td,
.earn_dividend tfoot,
.earn_dividend th,
.earn_dividend thead,
.earn_dividend tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border: 1px solid #4f5050;
  padding: 4px;
}

table {
  page-break-inside: auto;
}
tr {
  page-break-inside: avoid;
  page-break-after: auto;
}
