/* .wrapper {
  height: 500px !important;
} */
.top_100_slider1 .react-slideshow-container {
  background: #e9f7ef;
  /* border-radius: 20px; */
  margin-bottom: -1.5em;
  line-height: 1;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.top_100_slider2 .react-slideshow-container {
  /* background: lavenderblush; */
  background: #eaf2f8;
  /* border-radius: 20px; */
  margin-bottom: -1.5em;
  line-height: 1;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.top_100_slider3 .react-slideshow-container {
  background: #fdedee;
  /* border-radius: 20px; */
  margin-bottom: -1.5em;
  line-height: 1;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.top_100_slider4 .react-slideshow-container {
  background: #f4ecf7;
  /* border-radius: 20px; */
  margin-bottom: -1.5em;
  line-height: 1;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.top_100_slider5 .react-slideshow-container {
  background: #fbeefc;
  /* border-radius: 20px; */
  margin-bottom: -1.5em;
  line-height: 1;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.slider_web {
  display: block;
}
.slider_mobile {
  display: none;
}
