.pts_mobile_view {
  margin-top: 2em;
}

@media (max-width: 768px) {
  .alphabetList {
    color: blue !important;
  }
}
.alphabetList {
  color: #4154f1;
  text-decoration: none;
  cursor: pointer;
}
