.cba_section_header {
  text-align: left;
  text-decoration: underline;
}
.price_analysis {
  text-align: center;
  font-weight: bold;
}
.quarterYear {
  border-right: 1px solid;
  text-align: right;
}
.cba_section_Subheader {
  text-align: left;
  font-size: 1.1em;
  margin: -0.3em 0.3em;
}
.cba_table_data_space {
  margin: 1.2em 0em;
}
.cba_data_error_msg {
  padding: 3em 17em;
  font-weight: bold;
  text-decoration: underline;
  color: rgb(242, 109, 33);
}

.cba_data_error_msg_pdf {
  padding: 8em 6em;
  font-weight: bold;
  text-decoration: underline;
  color: rgb(242, 109, 33);
}
.cba_report_table th {
  text-align: right;
}
