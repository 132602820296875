.cart_button {
  background: rgb(245, 141, 81);
  font-size: 0.8em;
  float: right;
  border-radius: 10px;
  margin: auto 5px auto auto;
  border-bottom: 2px solid rgb(99, 98, 98);
}

@media (max-width: 768px) {
  .isAddToCart {
    display: none;
  }
  .footer {
    /* background: #444141 !important; */
    background: cadetblue !important;
  }
}
