.top100_rationale span {
  font-weight: 700;
}
.top100_rationale h4,
h5 {
  color: #305388 !important;
}
.pts_mobile_view {
  margin-top: 1.5em;
}
