.pts_list_content ul {
  font-family: monospace;
  font-size: 2em;
  list-style: none;
  margin: 2em;
  padding: 0;
}
.pts_list_content ul li {
  margin: 0;
  margin-bottom: 0.3em;
  padding-left: 1.5em;
  position: relative;
}
.pts_list_content ul li:after {
  content: "";
  height: 0.4em;
  width: 0.4em;
  background: rgb(242, 109, 33);
  display: block;
  position: absolute;
  transform: rotate(45deg);
  top: 0.25em;
  left: 0;
}
.pts_list_content ul li a {
  color: blue;
  font-size: 0.7em;
  text-decoration: underline;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  line-height: 0.8;
}
