p,
h2 {
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	font-size: 14.4px;
}
.headTxt h2 {
	color: #012970;
	font-weight: 700;
	font-size: 25px;
}
.conCompQua section {
	padding: 20px 0 10px 0;
	border-bottom: 2px solid black;
}
.secCompQua {
	border: none !important;
}
.secCompQua .headTxt {
	text-align: center;
	padding-bottom: 10px;
	color: #012970;
}
.spancss p span {
	color: blue;
}
.tableRatingcss {
	border: 1px solid black;
}
.tableRatingcss p {
	margin: 0px;
	padding-top: 5px;
}
.tableRatingcss tr {
	border-bottom: 1px solid black;
}
.tableRatingcss th {
	border-right: 1px solid black;
	text-align: center;
	width: 25%;
	font-weight: bold;
}
.tableRatingcss td {
	border-right: 1px solid black;
	text-align: center;
	width: 25%;
	padding: 5px;
	vertical-align: baseline;
}
.tableRatingcss td h6 {
	font-weight: bold;
}
.tableRatingcss td a {
	font-weight: bold;
	text-decoration: underline;
}
.trHead {
	background-color: #012970;
	color: #fff;
	font-size: 50px;
	font-weight: bold;
}
.trAWIL {
	font-style: italic;
}
.trAWIL td {
	text-align: left;
}
.spancss h2 {
	color: #012970;
	font-weight: 700;
	font-size: 20px;
}
