.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
::-webkit-scrollbar {
  height: 5px;
  width: 9px;
}
@media (max-width: 768px) {
  ::-webkit-scrollbar {
    height: 4px !important;
    width: 5px;
  }
  .text-secondary {
    color: #6c757d !important;
    text-align: center;
  }
  .Competitor_column {
    margin: 1em 0em !important;
  }
  .cba_mobile_view {
    margin-top: 3em !important;
  }
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    overflow: auto;
  }
  .ci_reportSnap_modal {
    display: inline !important;
  }
  .cart_btn {
    background: rgb(242, 109, 33);
    color: rgb(255, 255, 255);
    /* margin: 0px auto; */
    height: 100%;
    width: 100%;
    /* width: 100%; */
    border-bottom: 3px solid rgb(99, 98, 98);
    display: flex;
  }
  .cart_btn_space {
    padding: 1em;
  }
  .pts_mobile_view1 {
    margin-top: 2em !important;
  }
  .header1.header-scrolled {
    background: #fff;
    padding: 1em 0 0 0;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  }
  .header2.header-scrolled {
    background: #fff;
    padding: 0em 0 0 0;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  }
  .companySnapBreadcrumbs {
    padding: 0px 0px !important;
    background: #305388;
    margin-top: 57px;
    color: #fff;
    height: 2.8em;
  }
  .footerh4 {
    margin-top: 3em;
    background: #fff;
  }
  .footer .footer-top .ptsfooter-links h4 {
    color: #fff !important;
  }
  .text-md-start h4 {
    color: #fff !important;
  }
  .modalContain .modal-title .row {
    display: block !important;
  }
  .footer .footer-top h4 {
    color: #012970 !important;
  }

  .useful_links {
    margin-left: -3em !important;
  }
  .footer .footer-top .footer-contact p {
    font-size: 1em !important;
  }
  .slider_web {
    display: none !important;
  }
  .slider_mobile {
    display: block !important;
  }
  .react-slideshow-wrapper.slide {
    height: 6em !important;
  }
  .currency_web_view {
    display: none !important;
  }
  .currency_mobile_view_table {
    display: block !important;
  }
  .modalContain .modal-header {
    height: auto !important;
  }
  .tag_line_home {
    margin-top: 0em;
    margin-left: 0em !important;
  }
  .app_src_btn {
    display: block;
    float: left !important;
  }
  .top_100_filter_slide .react-slideshow-container {
    background: #eaf2f8;
    width: 106.75% !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
.modal-open {
  overflow-y: scroll !important;
}

.MuiFab-root {
  color: rgba(0, 0, 0, 0.87);
  width: 56px;
  height: 56px;
  padding: 0;
  font-size: 0.875rem !important;
  min-width: 0;
  /* box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%); */
  box-sizing: border-box;
  min-height: 26px !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 50%;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  background-color: #fff;
  cursor: pointer;
}
.modal_close_btn {
  float: right !important;
  font-size: small !important;
  height: 15px !important;
  width: 26px !important;
  background: #fff !important;
  cursor: pointer !important;
  color: white !important;
  border: 2px solid rgb(237, 240, 241) !important;
}
.modal-title .MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  color: #000 !important;
}
.modalContain .modal-header .unit_str {
  font-size: 0.6em;
  font-weight: bold;
  color: #ebedef;
  padding: 3px;
  font-style: italic;
}
.company_snap_tabs .MuiTabs-flexContainer {
  overflow-y: scroll;
}

.companyInfoAdd {
  display: block;
}
@media (max-width: 768px) {
  .companyInfoAdd {
    display: none !important;
  }
}
