/* .nav-tabs {
  border-bottom: 1px solid #dee2e6;
  float: right;
  font-weight: bold;
}
.tab-content {
  margin-top: 5em;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: transparent !important;
  border-bottom: 2px solid #013289 !important;
}

.MuiTableRow-root {
  border: none !important;
}

.MuiTableRow-root:nth-child(even) {
  background-color: rgb(220, 220, 220, 0.5) !important;
  border: none !important;
}

.MuiTableRow-root:not(:nth-child(odd)) {
  background-color: #ffffff;
  border: none !important;
}

.MuiTableCell-root {
  border: none !important;
} */

/* .cusTabs {
  border-bottom: 1px solid #dee2e6;
  float: right;
  font-weight: bold;
} */
.tab-content {
  margin-top: 5em;
}
/* .cusTabs .nav-item.show .nav-link,
.cusTabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: transparent !important;
  border-bottom: 2px solid #013289 !important;
} */

.topHunTable .MuiTableRow-root {
  border: none !important;
}
.topHunTable .MuiTableHead-root {
  display: table-header-group;
  font-size: 0.8em;
}
.topHunTable .MuiTableRow-root:nth-child(odd) {
  background-color: rgb(255, 255, 255) !important;
  border: none !important;
}

.topHunTable .MuiTableRow-root:not(:nth-child(odd)) {
  border: none !important;
}

.MuiTableCell-root {
  border: none !important;
}

.cusTabs {
  border-bottom: 1px solid #dee2e6;
  float: right;
  font-weight: bold;
}

.tab-content {
  margin-top: 5em;
}

.cusTabs .nav-item.show .nav-link,
.cusTabs .nav-link.active {
  /* color: #495057; */
  background-color: #fff;
  border-color: transparent !important;
  border-bottom: 2px solid #013289 !important;
}

.cusTabs .nav-link {
  margin-top: 10px;
  background-color: mediumaquamarine;
  margin-left: 35px;
  color: #fff;
}

.cusTabs .nav-link.active {
  /* background-color: mediumaquamarine; */
  margin-left: 35px;
  color: mediumaquamarine !important;
  border-bottom: 2px solid mediumaquamarine !important;
}

.cusTabs {
  border-bottom: 1px solid #dee2e6;
  margin-top: 10px;
}

/* .MuiTableRow-hover :hover
{
  background-color: silver !important;
} */

.topHunTable .MuiTableRow-root:hover {
  background-color: lightgray !important;
}

.MuiPaper-root {
  border: 2px solid F8F8F8;
  box-shadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" !important ;
}

/* .MuiPaper-root
{
border: 2px solid F8F8F8;
box-shadow:
5px 5px 8px blue, 10px 10px 8px red, 15px 15px 8px green !important;
} */

.card {
  border: none !important;
}

/* .advanceFeature
{
  background-color: #E7F5F3 !important;
} */

.exchange_h6 {
  line-height: 0.3em;
  font-weight: bolder;
  text-align: left;
  padding-top: 20px;
  padding-left: 20px;
}

@media (max-width: 768px) {
  .pts_mobile_view {
    margin-top: 4em !important;
  }
  .pts_isAdvertisement {
    display: none;
  }
  .cusTabs {
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 15px !important;
  }

  .cusTabs .nav-link {
    margin-top: 10px;
    margin-left: 2px !important;
    width: 100%;
  }

  .cusTabs .nav-link.active {
    margin-left: 2px !important;
  }
  .top_hundred .MuiTabs-fixed {
    overflow: auto;
    /* background: #fff; */
    width: 100%;
    margin-left: -24px;
    /* z-index: 1; */
    /* margin-top: -0.8em; */
  }
}
.tooltip_box {
  background-color: #fff;
  height: 4em;
  padding: 15px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
/* .fiscalCSS {
  color: red !important;
} */

.breadcrumbs_fix_top_blank {
  position: fixed;
  width: 101%;
  margin-top: -1.6em;
  margin-bottom: 0em;
  z-index: 1;
}

.top_hundred .MuiTab-root {
  font-size: 0.925rem;
  text-align: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: bold !important;
  line-height: 1.75;
  white-space: normal;
  letter-spacing: 0.02857em;
  text-transform: capitalize;
  margin: 0px 15px;
}
.top_hundred .MuiTab-textColorPrimary.Mui-selected {
  color: rgb(242, 109, 33);
  margin: 0px 15px;
}
.top_hundred .MuiTabs-fixed {
  overflow: hidden;
  /* background: #fff; */
  width: 100%;
  margin-left: -24px;
  /* z-index: 1; */
  /* margin-top: -0.8em; */
}

.pts_mobile_view {
  margin-top: 1em;
}
